*{
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}

*:before, *:after{
	box-sizing: border-box;	
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	font: inherit;
	vertical-align: baseline;
	line-height: 1.4;
}

html{
	font-size: 62.5%;
}

html, body{
	width: 100%;
	height: 100%;
}

body{
	font-family: "Roboto", Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	color: $text-color-default;
	text-rendering: optimizelegibility;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block;
}

a{
	text-decoration: none;
	border: 0;
}

img{
	max-width: 100%;
	vertical-align: middle;
}

strong, b{
	font-weight: 700;
}

em{
	font-style: italic;
}

button, input[type="submit"]{
	cursor: pointer;
	border: 0;
	font-family: "Roboto", Arial, sans-serif;
	font-size: 16px;
}

input, textarea, select{
	font-family: "Roboto", Arial, sans-serif;
	font-size: 16px;
	
	&.incorrect, &.error{
		border-color: $error-default;
	}
	
	&.correct, &.success{
		border-color: $success-default;
	}
	
	&:invalid{
		box-shadow: none;
	}
}

input[type="search"]{
	appearance: none;
}

textarea{
	resize: vertical;
}

iframe{
	position: relative;
}

.header, .main, .footer{
	width: 100%;
	display: block;
	clear: both;
}